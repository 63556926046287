import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import React, { useState } from "react";

const Debug = ({ debugValue }) => {
  const [debugSwitch, setDebugSwitch] = useState(false);

  return (
    <>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="debug" mb="1">
          Debug
        </FormLabel>
        <Switch
          id="debug"
          size='sm'
          onChange={() => {
            setDebugSwitch(!debugSwitch);
            debugValue(!debugSwitch);
          }}
        />
      </FormControl>
    </>
  );
};

export default Debug;
