import { Button, Center, Flex } from "@chakra-ui/react";
import React, { useState } from "react";

const KeyboardLetter = ({ letterData, isDisabled, func }) => { // define KeyboardLetter, takes three params
  return (
    <Button
      bg="cyan.800"
      width={10}
      height={10}
      disabled={isDisabled}
      onClick={func}
    >
      {letterData}
    </Button>
  );
};

const Keyboard = ( {setLetter, keyboardDisabled} ) => { 
  const [pressedKey, setPressedKey] = useState([]);
  const firstLine = "QWERTYUIOP"; 
  const secondLine = "ASDFGHJKL";
  const thirdLine = "ZXCVBNM";

  const onKeyPress = (pressedLetter) => { 
    setPressedKey([...pressedKey, pressedLetter]); 
    setLetter(pressedLetter)
  };

  return (
    <>
      <Flex gap="5px" flexDirection={"column"}>
        <Center display={"flex"} gap="4px">
          {firstLine.split("").map((letter) => {
            return (
              <KeyboardLetter
                letterData={letter}
                isDisabled={pressedKey.includes(letter) || keyboardDisabled}
                func={() => onKeyPress(letter)}
              />
            );
          })}
        </Center>
        <Center display={"flex"} gap="4px">
          {secondLine.split("").map((letter) => {
            return (
              <KeyboardLetter
                letterData={letter}
                isDisabled={pressedKey.includes(letter) || keyboardDisabled}
                func={() => onKeyPress(letter)}
              />
            );
          })}
        </Center>
        <Center display={"flex"} gap="4px">
          {thirdLine.split("").map((letter) => {
            return (
              <KeyboardLetter
                letterData={letter}
                isDisabled={pressedKey.includes(letter) || keyboardDisabled}
                func={() => onKeyPress(letter)}
              />
            );
          })}
        </Center>
      </Flex>
    </>
  );
};

export default Keyboard;
