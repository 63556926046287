import "./App.css";
import {
  Box,
  Container,
  Text,
  Flex,
  Button,
  useToast,
  Center,
  Divider,
  VStack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import Keyboard from "./components/Keyboard";
import Debug from "./components/Debug";

function App() {
  const [lives, setLives] = useState(5);
  const [word, setWord] = useState("");
  const [incorrectLetters, setIncorrectLetters] = useState([]);
  const [correctLetters, setCorrectLetters] = useState([]);
  const [debugMode, setDebugMode] = useState(false);

  const toast = useToast();

  const containsLetter = (inputLetter) => {
    let cb = false;
    word.split("").forEach((letter) => {
      if (inputLetter === letter) {
        cb = true;
      }
    });
    return cb;
  };

  const checkIfWon = () => {
    let cb = true;
    word.split("").forEach((letter) => {
      if (!correctLetters.includes(letter)) {
        cb = false;
      }
    });
    return cb;
  };

  const handleKeyboardChange = (data) => {
    const keyLowercase = data.toLowerCase();
    if (containsLetter(keyLowercase)) {
      if (!correctLetters.includes(keyLowercase)) {
        setCorrectLetters([...correctLetters, keyLowercase]);
      }
    } else {
      if (keyLowercase === " ") {
        toast({
          title: `Space is not a valid key`,
          status: "error",
          isClosable: true,
        });
      } else {
        if (!incorrectLetters.includes(keyLowercase)) {
          setIncorrectLetters([...incorrectLetters, keyLowercase]);
          setLives(lives - 1);
        }
        toast({
          title: `Incorrect letter ${keyLowercase}`,
          status: "error",
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    fetch("https://random-word-api.herokuapp.com/word?number=1")
      .then((res) => res.json())
      .then(
        (result) => {
          setWord(result[0]);
        },
        (error) => {
          throw error;
        }
      );
  }, []);

  const refreshPage = () => {
    window.location.reload(false);
  };

  return (
    <div class="noselect">
      <Box display="flex">
        <Divider />
        <Box align='right' paddingRight={'10px'}>
          <Debug debugValue={() => setDebugMode(!debugMode)} />
        </Box>

      </Box>

      <Divider />

      <Flex justify="center">
        <Text fontWeight="bold" fontSize="4xl">
          Hangman
        </Text>
      </Flex>
      <br></br>
      <VStack justify="center">
        <Text>{`You have ${lives} lives left!`}</Text>
        <Text>{debugMode ? `The current word is ${word}` : ""}</Text>
        <Text>{lives === 0 && debugMode === false ? `The word was ${word}` : ""}</Text>
      </VStack>
      <br></br>
      <Box>
        <Container>
          <Center>
            <Flex gap="3px">
              {word.split("").map((letter) => {
                return correctLetters.includes(letter) ? (
                  <Center>
                    <Box
                      minWidth={6}
                      minHeight={6}
                      border={"1px solid #086F83"}
                      borderRadius={5}
                      textAlign="center"
                      marginBottom={1}
                    >
                      {letter}
                    </Box>
                  </Center>
                ) : (
                  <Box
                    minWidth={6}
                    minHeight={6}
                    border={"1px solid #086F83"}
                    borderRadius={5}
                    textAlign="center"
                    marginBottom={1}
                  ></Box>
                );
              })}
            </Flex>
          </Center>
        </Container>
        <br></br>
        <Keyboard
          setLetter={handleKeyboardChange}
          keyboardDisabled={lives === 0 || checkIfWon() ? true : false}
        />
      </Box>
      <Box>
        <Container>
          {checkIfWon() ? (
            <>
              <br></br>You won!<br></br>
              <Button onClick={refreshPage}>Reload Game</Button>
            </>
          ) : (
            ""
          )}
        </Container>
        <Container>
          {lives === 0 ? (
            <>
              <br></br>You lost!<br></br>
              <Button onClick={refreshPage}>Reload Game</Button>
            </>
          ) : (
            ""
          )}
        </Container>
      </Box>
    </div>
  );
}

export default App;
